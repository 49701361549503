/**
 * An object type representing all stringifiable object values.
 * `null` values are converted to empty query params.
 * `undefined` values are removed from the query string.
 *
 * Arrays are not supported. If you need to store array-type data in the URL, serialize it to a string first.
 */
export type ValidQueryParamsObject = Record<string, ValidQueryParamValue>;

/**
 * A union of all valid query param values.
 * `null` and `undefined` values are removed from the URL.
 * Empty string values are preserved as empty query parameters.
 */
export type ValidQueryParamValue = string | null | undefined;

/**
 * Parse the `location.search` string to an object using URLSearchParams.
 *
 * Array-type params (repeated keys, keys ending in `[]`) are not supported.
 * If you need to get array-type data from a param, parse the string-type param in your own code.
 */
export const parse = (
  queryString: string,
  // TODO: remove `| undefined` when FEP-264 is fixed
): Record<string, string | undefined> => {
  const obj: Record<string, string> = {};
  if (!queryString) {
    return obj;
  }

  const params = new URLSearchParams(queryString);
  for (const [key, value] of params.entries()) {
    if (key) {
      obj[key] = value;
    }
  }
  return obj;
};

/**
 * Stringify an object of query params into a string using URLSearchParams.
 * `null` and `undefined` values are removed from the query string.
 *
 * Array-type params (repeated keys, keys ending in `[]`) are not supported.
 * If you need to store array-type data in a param, serialize it to a string before passing it to this function.
 */
export const stringify = (obj: ValidQueryParamsObject): string => {
  const params = new URLSearchParams();
  // iterating over the object here so we can apply custom logic to prop setting,
  // otherwise values like `null`/`undefined` get stringified to `"null"`/`"undefined"` and empty values are not excluded.
  for (const key in obj) {
    const value = obj[key];
    if (key && typeof value === "string") {
      params.set(key, value);
    }
  }
  params.sort();
  return params.toString();
};

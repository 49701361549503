import { datadogRum } from "@datadog/browser-rum";
import { parse } from "qs";
import { purgeExpiredStates, saveState } from "./state";
import { isTokenValid } from "@/features/Authentication/helpers/isTokenValid";
import { getToken } from "@/features/Authentication/helpers/tokenStorage";
import { stringify } from "@/helpers/queryString";
import { unauthedUrls } from "@/helpers/unauthedUrls";

/**
 * Force-disable auth redirect if query param is set
 */
const DISABLE_REDIRECT_KEY = "disable-auth-redirect";
const isRedirectDisabled = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectQueryParam = params.get(DISABLE_REDIRECT_KEY);
  if (redirectQueryParam != null) {
    const redirectDisabled =
      redirectQueryParam !== "false" && redirectQueryParam !== "0";
    localStorage.setItem(DISABLE_REDIRECT_KEY, String(redirectDisabled));
    return redirectDisabled;
  }

  const redirectDisabled = localStorage.getItem(DISABLE_REDIRECT_KEY);
  return redirectDisabled === "true";
};

/**
 * Generate a random value using window.crypto.
 */
const generateRandomValue = (): string => {
  return btoa(crypto.getRandomValues(new Uint8Array(32)).toString());
};

/**
 * Generate a PKCE verifier.
 */
const generatePkceVerifier = (): string => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  return Array.from({ length: 128 }, () =>
    charset.charAt(Math.floor(Math.random() * charset.length)),
  ).join("");
};

/**
 * Convert a string to ArrayBuffer and Base64 URL encode a hash.
 */
const generateCodeChallenge = async (pkceVerifier: string): Promise<string> => {
  const buffer = new TextEncoder().encode(pkceVerifier);
  const digest = await crypto.subtle.digest("SHA-256", buffer);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

/**
 * Determine if the user should be redirected to login.
 */
const shouldRedirectToLogin = (): boolean => {
  const redirectDisabled = isRedirectDisabled();
  if (redirectDisabled) {
    return false;
  }

  const authRequired = !Object.values(unauthedUrls).includes(
    window.location.pathname,
  );
  if (!authRequired) {
    return false;
  }

  return !isTokenValid(getToken()?.accessToken);
};

enum AuthenticationRedirectAction {
  Begin = "AuthenticationRedirectBegin",
  Success = "AuthenticationRedirectSuccess",
  Error = "AuthenticationRedirectError",
}

/**
 * Redirect to login if user is not authed
 */
export const redirectToLoginIfUnauthed = async () => {
  try {
    const redirect = shouldRedirectToLogin();
    datadogRum.addAction(AuthenticationRedirectAction.Begin, {
      redirect,
    });

    if (!redirect) {
      return false;
    }

    purgeExpiredStates();

    const redirectUri = `${process.env.REACT_APP_BASE_URL}/auth.html`;
    const id = generateRandomValue();
    const nonce = generateRandomValue();
    const pkceVerifier = generatePkceVerifier();
    const codeChallenge = await generateCodeChallenge(pkceVerifier);

    const currentSearch = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const search = stringify({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      client_id: process.env.AUTHZ_CLIENT_ID!,
      redirect_uri: redirectUri,
      response_type: "code",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scope: process.env.AUTHZ_SCOPE!,
      state: id,
      nonce,
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      login_url:
        process.env.APP_ENV === "localhost"
          ? process.env.AUTHZ_ENDPOINT
          : undefined,
      iss: currentSearch.iss,
    });

    const originalLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    saveState({
      id,
      pkceVerifier,
      redirectUri,
      state: {
        originalLocation,
      },
    });

    datadogRum.addTiming("app.redirect.signin");
    datadogRum.addAction(AuthenticationRedirectAction.Success);
    const uri = `${process.env.OKTA_AUTHZ_ENDPOINT}/v1/authorize?${search}`;
    window.location.replace(uri);
    return true;
  } catch (error) {
    datadogRum.addError(error);
    datadogRum.addAction(AuthenticationRedirectAction.Error, {
      error,
    });
    return false;
  }
};

// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: primitives.[feature-flag-name]

import type { StaticGateMetadataMap } from "@/features/FeatureFlags/sharedTypes";

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 *
 * @deprecated These flags are no longer mantained and only work in the legacy `useFeatureFlags`
 * hook. For Statsig support, please use {@link primitivesGates} for boolean flags and
 * {@link primitivesDynamicConfigs} for dynamic configs.
 */
const legacyFlags = [
  ["empower-receipt-affidavit", true],
  ["primitives-mobile-upsells", false],
  ["primitives-mobile-upsells-reset-state", false],
  ["accounts-module-v2", false],
  ["show-credit-alerts-banners", false],
  // Default to true to preserve correct behavior on Empower Full
  ["primitives-parent-budget-reassignment", false],
  ["bill-pay-empower-eba-international-wire-countries", [] as string[]],
  // Repayments
  ["mm-employee-repayment-v1", false],
  ["pup-edd-repayment", false],
  ["dda-l3", false],
  ["allow-manually-connected-funding-source", false],
  ["empower-expense-categories", false],
  // Multi-entity
  ["multi-entity-reimbursements-frontend", false],
  ["multi-entity-configure-reimbursements-tracking", false],
  ["multi-entity-configure-reimbursements-tracking-for-us-entities", false],
  ["multi-entity-editions-frontend", false],
  ["integrations.change-integration-spending-entity-whitelist", false],
  ["empower-account-timezones", false],
  ["reimbursements-auto-retry-failed-after-fixes", false],
  ["local-autopay-enabled", false],
  ["local-autopay-enablement-banner", false],
  // Policy
  ["dynamic-policy-doc-approval-chain", false],
  /**
   * this is a Roblox on-off request
   * it is not recommended practice to accept one-off requests from customers
   * here are some context, on why we are doing it in this case:
   * https://brex.slack.com/archives/C02JJC85BSR/p1694550695805079?thread_ts=1694549899.476039&cid=C02JJC85BSR
   * https://github.com/brexhq/credit_card/pull/154958
   */
  ["is-per-diem-travel-day-restriction", false],
  ["expense-category-based-policy-rules", false],
  ["travel-essentials-consent-gather", false],
  ["travel-spotnana-twilio-chat-enabled", false],
  ["show-budgets-table-template-filter", false],
  ["temp-budgets-table-status-filter-disable-top-level", false],
  ["expense-policy-reasons-v3-fe", false],
  ["25q1-policies-30", false],
  // allow mcc controls to be set on a spend limit restriction,
  ["b24-mcc-controls", false],
  // Budget templates
  ["dynamic-currency-policy", false],
  ["travel-budget-estimator", false],
  // Expenses
  ["empower-expenses-approval-status-grouping", false],
  ["empower-budget-timezones", false],
  ["precompute-integration-lens", "OFF"],
  ["empower-accounting-custom-report-accounting-education-cohort", false],
  [
    "empower-accounting-custom-report-accounting-education-not-exported-days",
    -10000,
  ],
  ["empower-accounting-streamlined-rules-vat-action", false],
  ["empower-accounting-export-history-m1", false],
  ["empower-accounting-export-history-m2", false],
  ["empower-accounting-journal-v2-suggestions", false],
  ["empower-accounting-journal-v2-bulk-suggestions", false],
  ["empower-accounting-journal-sort-by-posted-date", false],
  ["empower-accounting-streamlined-rules-bulk-suggestions", 10000],
  ["spend-accounting-self-serve-unexport", false],
  ["empower-accounting-vat", false],
  ["empower-accounting-vat-1.5", false],
  ["empower-axp-and-int-merchant-to-vendor", false],
  ["empower-extended-fields-compliance-filters", false],
  ["delete-accounting-fields-on-csv-integration-disable", false],
  ["custom-mileage-rate-69-cents", false],
  ["accounting-automation-m2", false],
  ["25q1-accounting-automation-l2-page", false],
  ["accounting-managing-custom-fields", false],
  ["accounting-managing-custom-field-option-by-budget-templates", false],
  ["accounting-managing-custom-fields-bypass-employee-only-visibility", false],
  ["accounting-managing-custom-fields-permissions", false],
  ["custom-accounting-field-billpay-universal-csv", false],
  ["accounting-vx-workflow-enabled", false],
  ["accounting-journal-initial-page-size", 25],
  ["accounting-journal-datagrid-receipt-column", false],
  ["accounting-journal-nested-budget-filter", false],
  ["accounting-journal-datagrid-render-logs", false],
  ["accounting.credit-gls", false],
  ["25q1-enable-accruals-by-default", false],
  ["accounting.qbo-sage-fusion-accruals", false],
  ["dependent-list-erp-import", false],
  ["accounting-field-dependent-list", false],
  ["accounting-field-dependent-list-csv-download", false],
  ["accounting-categorization-applying-rules-banner", false],
  ["empower-create-card-enabled", false],
  ["empower-uber-integration", false],
  ["empower-accounting-ftux-suggestions-rearch", false],
  ["expense-improvements-for-reimbursements", false],
  ["edp-v-2-expense-category-suggestions", false],
  ["accounting.1-brex.vat-m1", false],
  ["accounting.datagrid-jobs-menu", false],
  ["accounting.fields-refresh-and-auto-sync-menu", false],
  ["24q2-accounting.export-validation-m1", false],
  ["expense-edit-entity-enabled", false],
  ["empower-expense-autosave-v1", false],
  ["spend-expenses-custom-hierarchy-fields", false],
  ["growth-empower-move-autopay", false],
  ["essentials-stipends-enabled", false],
  ["expense-attendee-suggestions", false],
  ["shared-expense-budget-selection-dropdown", false],
  ["expenses-compliance-filter-per-diem-violation", false],
  ["expenses.hide-non-compliant-expenses", false],
  ["fraud-alerts-primitives", false],
  [
    "vat-legal-entity-visibility-override",
    "TGVnYWxFbnRpdHk6bGVfY2w0b2pldGNlMDE4djBsMzVyc2VhdXd0eA==",
  ],
  ["budget-templates-disabled", false],
  ["svb-empower-implementation", false],
  ["bulk-invite-increased-page-size", false],
  ["brex-assistant", false],
  ["brex-assistant-chat-debug", false],
  ["brex-assistant-milestone-2", false],
  ["wallet-v2-tooltip-tour", false],
  ["new-virtual-card-takeover-infinite-scroll", false],
  ["budgets-v2-exclusion-list", false],
  ["show-bifurcation-prelaunch-banner", false],
  ["show-bifurcation-prelaunch-banner-empower-plus", false],
  ["create-form-member-exclusions", false],
  ["accounting-infinite-scroll", false],
  ["card-ownership-transfer-flow", false],
  ["hris-changelog", false],
  ["accounting.1brex-phase1-qbo", false],
  ["multi-entity-accounting-legacy", false],
  ["global-search", false],
  ["global-search-account-menu-entry", false],
  ["post-auth-budget-suggestions", false],
  ["cip-refresh-enabled", false],
  ["ny-demographics-enabled", false],
  ["ownership-resequence-enabled", false],
  ["first-spend-launch-date", "9999-12-31"],
  ["budget-setup-helpers-netsuite-integration", false],
  ["spend-limits-datagrid", false],
  ["spend-improve-request-types-on-inactive-parent-budget", false],
  ["pci-card-legacy-mode-enabled", true],
  ["ai-comment-settings-and-upsells", false],
  ["ai-review-settings", false],
  ["tmc-card-m-1-enabled", false],
  ["expense-details-travel-data", false],
  ["lodge-card-request-plan", false],
  ["remove-mileage-reimbursements", false],
  ["chat-in-slack", false],
  ["override-erp-multi-entity-supported", false],
  ["card-expense-itemization-enabled", true],
  ["mileage-expense-category-default-option-identifier", ""],
  ["disable-expense-entities-dropdown-for-employees", false],
  ["25q1-pcard-taxonomy", false],
  ["25q1-custom-roles-expenses-authz", false],
  ["audit-publish-card-events-fe", false],
  ["expenses-audit-migration-to-audit-fe", false],
  // Accounting
  ["me-accounting-onboarding-m1", false],
  ["accounting-vat-automation", false],
  ["allow-exp-cat-update-post-export", false],
  ["24q1-brex-file-transfer", false],
  ["hide-accounting-journal-unexport", false],
  ["accounting-expense-categories-self-serve", false],
  ["essentials-v-2-toggle-custom-rules", false], // Fast follow from Fall 24 release
  // Integrations
  ["integrations.locally-funded-reimbursements", false],
  ["integrations.accrual_basis_reimbursements_phase_3", false],
  ["integrations.vat-gl-account-settings", false],
  ["integrations.netsuite_employee_field", false],
  ["integrations.multiple-legal-entity-mappings", false],
  ["spend.deprecate-settle-expense", false],
  ["auto-invite-settings", false],
  ["decoupled-access-travel-vip-user", false],
  ["expense-admin-role", false],
  ["expenses-ai-smart-filtering", false],
  ["empower-card-pan-cvv-query-rerouting", false],
  ["empower-card-pan-cvv-query-rerouting-compare-with-legacy-results", false],
  ["empower-card-pan-cvv-query-rerouting-shadow-mode", false],
  ["spend-auto-budget-assignment-enabled", false],
  ["shipping-address-phone-number", false],
  // Reporting
  ["travel-reporting", false],
  ["reporting-new-export-expenses", false],
  ["reporting-budget-filters-enabled", false],
  ["reporting-negative-bars", false],
  ["reporting-user-entity", false],
  // Search
  ["expenses-prototype-local-search", false],
  ["spend.global-search.help-center-timeout", -1],
  // Semantic Search
  ["expense-filters-vendor-category-semantic-search-enabled", false],
  // Airbnb demo
  ["hail-mary-task-inbox-show-pending-card-expenses", false],
  ["hail-mary-hide-non-blocking-errors", false],
  ["expenses-data-table-playground-enabled", false],
  ["accounting-journal-datagrid-fixed-layout", false],
  ["user-groups-enabled", false],
  // Spend migration
  ["spend-migration-sunlight-enabled", false],
  // Banking
  ["banking-cuj-surveys", false],
  ["banking-demo", false],
  ["banking-dda-enabled", false],
  ["banking-dda-automatic-transfers", false],
  ["charge-principal-protection-fee", false],
  ["cheque-attachments-enabled", false],
  // Expense reports
  ["expense-reports-test-1-tasks-on-wallet", false],
  ["expense-reports-test-2", false],
  /**
   * Begin Datadog one-off request flags
   */
  ["budget-req-templates-without-custom-contract-field", ""], // DO NOT DELETE
  ["budget-request-contract-field-email", ""], // DO NOT DELETE
  /**
   * End Datadog one-off request flags
   */
  ["hide-expenses-table-error", false],
  ["datagrid-on-wallet", false],
  ["tasks-inbox-new-ia", false],
  ["accidental-decline-protection", false],
  ["expense-edit-entity-enabled", false],
  ["candidate-reimbursements", false],
  ["integrations.accounting_money_movement_refunds", false],
  ["expense-edit-entity-post-clearing-enabled", false],
  // OneTimePayment
  ["billing-use-early-crediting-v-3", false],
  [
    "banking-integrations-billing.bpi-collection-promises-migration-billing-groups",
    false,
  ],
  // Removing budget program of shared spend limits
  ["shared-spend-limits-without-budget-program", false],
  ["team-archived-status-filter-enabled", false],
  ["datagrid-expenses-fixed-layout-fullscreen", false],
  // #region 25Q1
  // All 25 Q1 Flags should live in this region, sorted alphabetically:
  ["25q1-backend-recent-global-searches", false],
  ["25q1-backend-recent-local-searches", false],
  ["25q1-batched-bill-pay", false],
  ["25q1-cards-and-limits-m3", false],
  ["25q1-expense-reports-m2", false],
  ["25q1-expense-reports-m3", false],
  ["25q1-fields-library", false],
  ["25q1-fields-team-update", false],
  ["25q1-hris-extended-fields-enabled", false],
  ["25q1-pcard-taxonomy", false],
  ["25q1-policies-30", false],
  ["25q1-policy-require-accounting-fields", false],
  ["25q1-prevent-rewards-miles-access", false],
  ["25q1-side-navigation-sectioning", false],
  ["25q1-team-out-provider", false],
  // #endregion
] as const;

/**
 * @deprecated These flags are no longer maintained and only work in the legacy `useFeatureFlags`
 * hook. For Statsig support, please use {@link primitivesGates} for boolean flags and
 * {@link primitivesDynamicConfigs} for dynamic configs.
 */
export default legacyFlags;

/**
 * Stores all boolean feature flags used by Statsig.
 *
 * Please follow the FF naming convention (25q1-proj-name) and sort them alphabetically.
 */
export const primitivesGates = [
  // Follow the FF naming convention (25q1-proj-name) and sort them alphabetically.
  "251q-onboarding-expense-policy",
  "25q1-accounting-automation-l2-page",
  "bill-pay-skip-invoice-ocr",
  "25q1-accounting-field-dependent-list-csv-upload",
  "25q1-backend-recent-global-searches",
  "25q1-backend-recent-local-searches",
  "25q1-bill-pay-form-revamp",
  "25q1-cards-and-limits-m3",
  "25q1-consolidating-settings",
  "25q1-credit-management-center",
  "25q1-custom-roles-accounting-authz",
  "25q1-custom-roles-hub",
  "25q1-custom-roles-invite-changes",
  "25q1-custom-roles-team-domain",
  "25q1-dashboard-architecture-migration-accounting",
  "25q1-dashboard-architecture-migration-trips",
  "25q1-dashboard-read-from-bill-extensions",
  "25q1-employee-csv-import",
  "25q1-employee-csv-import-custom-field-form",
  "25q1-enable-customer-for-three-step-reimbursement-as-vendorbill",
  "25q1-entity-settings-erp-dependencies",
  "25q1-enable-physical-p-cards",
  "25q1-expense-approvals-improvement",
  "25q1-expense-reports-m2",
  "25q1-field-value-rules-for-visibility-and-dependency",
  "25q1-field-value-visibility-rules",
  "25q1-fields-library",
  "25q1-fields-team-update",
  "25q1-hris-extended-fields-enabled",
  "25q1-navan-card-partner-filter-credit-billing",
  "25q1-navy-m3-team-domain",
  "25q1-location-per-diem",
  "25q1-pcard-taxonomy",
  "25q1-penny-pull-retry",
  "25q1-pilotuwnonpi-cash-based-uw",
  "25q1-policies-30",
  "25q1-policy-require-accounting-fields",
  "25q1-prevent-rewards-miles-access",
  "25q1-razor-pay-kyc-setup",
  "25q1-saved-views",
  "25q1-self-serve-sso-settings",
  "25q1-side-navigation-sectioning",
  "25q1-simplified-credit-header",
  "25q1-spend-migration-overhaul",
  "25q1-team-out-provider",
  "25q1-travel-rewards-deprecation-company-shelf",
  "25q1-travel-rewards-deprecation-new-badge",
  "25q1-travel-rewards-deprecation-redemption-redirect",
  "25q1-travel-reporting-m2",
  "25q1-user-groups-in-old-policy-builder",
  "25q1-vendor-tin-validation",
  "25q1-vendor-w9-ocr-vss-portal",
  "25q1-travel-trip-details-pane-announcements",
  "25q2-ams-to-admin-home",
  "25q2-beta-features-section",
  "25q2-billboards-revamp",
  "25q2-bookings-datagrid",
  "25q2-credit-reimbursement-custom-roles",
  "25q2-travel-page-migration",
  "25q2-custom-roles-bulk-role-change",
  "25q2-document-center",
  "25q2-settings-copilot",
  "25q2-team-page-redesign",
  "25q2-edp-relay-migration",
  "25q2-edp-partner-fields",
  "25q2-expense-reports-basic-submitter-journey",
  "25q2-vendor-purchase-orders",
  "dependent-list-erp-import",
  "accounting-custom-rules-vat-action",
  "accounting-field-dependent-list",
  "accounting-field-dependent-list-csv-download",
  "accounting_oracle-fusion-disable-auto-posting",
  "accounting-settings-v-2",
  "accounting-vat-visibility-query",
  "accounting_export-accruals-v2-promotion",
  "25q1-enable-accruals-by-default",
  "accounting_in-progress-rules-in-jobs-menu",
  "accounting_qbo-sage-fusion-accruals",
  "accounting_zd-persisted-views",
  "accounting-bulk-csv-mappings",
  "audit-publish-card-events-fe",
  "b24-mcc-controls",
  "bill_pay_bulk_approval",
  "commenting-task-copilot",
  "embedded-netsuite-oauth-redirect-uri",
  "empower-accounting-vat",
  "empower-expense-categories",
  "empower-extended-fields-compliance-filters",
  "expenses-audit-migration-to-audit-fe",
  "expenses-compliance-filter-per-diem-violation",
  "export_bill_payment_as_journal_entry",
  "gl-show-retry-options-on-connection-error",
  "integrations-dd-fields",
  "integrations-track-locally-funded-reimbursement-collections",
  "integrations_multiple-legal-entity-mappings",
  "25q1-user-sanction-screening-web",
  "mileage-evidence-to-receipt-migration",
  "mm-employee-repayment-v1",
  "policies-new-canvas-ui",
  "25q1-policies-30-add-section-button",
  "25q1-prefill-memo-in-policy-builder",
  "policies-30-canvas-only",
  "policies-30-do-not-require-rules",
  "policies-30-direct-onboarding",
  "spend-expenses-custom-hierarchy-fields",
  "spend_global-search_banking",
  "spend_migration_add_custom_vendors",
  "task-clawback-pob-approval-reads",
  "team-archived-status-filter-enabled",
  "travel-preloader-spotnana-logout",
  "travel-support-chat-session",
  "expenses-v2-dg",
  "travel-use-spotnana-dev-environment",
  "real-time-balance-check-disabled",
  "25q1-personal-brex-trial",
  "25q1-downloads-platform",
  "scoped-search-full-width-enabled",
  "user-groups-enabled",
] as const;

/**
 * Stores all dynamic configs used by Statsig.
 *
 * Please follow the FF naming convention (25q1-proj-name) and sort them alphabetically.
 */
export const primitivesDynamicConfigs = [
  "25q1-reissue-card-same-pan",
  "25q1-spend-migration-speed-to-spend-entrypoints-variations",
  "travel-rewards-deprecation-cutoff-date",
  "rewards-ascenda-shutdown",
] as const;

export const primitivesStaticGates = {
  "25q1-r18": {
    shouldTriggerReload: true,
    keys: {
      effectiveValueCookieName: "r18",
      queryParamsCookieName: "r18-query-param",
      gateEvaluationCookieName: "r18-feature-flag",
      queryParamName: "r18",
    },
  },
  "25q2-critical-path-architecture-migration": { shouldTriggerReload: false },
  "25q2-refresh-token-rewrite": { shouldTriggerReload: false },
} as const satisfies StaticGateMetadataMap;

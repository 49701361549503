// @NOTE: This is separated from `urls.ts` because it's used by the `AuthenticationRedirect`. We want to load
// a very tiny portion of JS before redirecting to the login page.
export const unauthedUrls = {
  signupActivate: "/signup/activate",
  forgotPassword: "/forgot-password",
  loginWithSession: "/login-with-session",
  loginFinish: "/login/finish",
  logout: "/logout",
  signup: "/signup",
  switchTenant: "/switch-account",
  error: "/error",
  oauthGoogleMobile: "/oauth/google/mobile",
  onboardingRejected: "/onboarding/unable-to-approve",
  qboDisconnected: "/quickbooks-integration-disconnected",
  gmailFilter: "/receipts/gmail-filter",
  usersActivate: "/users/activate",
  activateMobile: "/users/activate/mobile",
  passwordReset: "/users/password_reset",
  emailChange: "/users/email-change",
  helpshiftLiveChatWindow: "/lc",
  pay: "/pay",
  billPayCard: "/bill-pay-card",
  billPayCardTmp: "/bill_pay_card",
  dismissAlert: "/dismiss-alert",
  payInvoice: "/pay-invoice",
  issuingIframe: "/issuing-iframe",
};
